import React from 'react';
import '../../App.css';
import Price from '../Price';
import Footer from '../Footer';
import TabTitle from '../utilis/TabTitle';
import { Helmet } from 'react-helmet-async';

export default function Cennik() {
	TabTitle('Cennik');
	return (
		<>
			<Helmet>
				{/* <!-- HTML Meta Tags --> */}
				<title>Cennik</title>
				<link rel='canonical' href='https://www.fizjo-medica.eu/cennik' />
				<meta name='description' content='Cennik' data-react-helmet='true' />
				<meta
					name='keywords'
					content='fizjoterapia, tyczyn, NFZ, zabiegi, USG, rehabilitacja, suche igłowanie, ćwiczenia, masaż, zdrowie, cennik'
					data-react-helmet='true'
				/>

				{/* <!-- Google / Search Engine Tags --> */}
				<meta
					itemprop='description'
					content='Cennik'
					data-react-helmet='true'
				/>
				<meta
					itemprop='keywords'
					content='fizjoterapia, tyczyn, NFZ, zabiegi, USG, rehabilitacja, suche igłowanie, ćwiczenia, masaż, zdrowie, cennik'
					data-react-helmet='true'
				/>
			</Helmet>
			<Price />
			<Footer />
		</>
	);
}
