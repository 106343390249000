import React from 'react';
import '../../App.css';
import MainIglowanie from '../MainIglowanie';
import Footer from '../Footer';
import TabTitle from '../utilis/TabTitle';

function Iglowanie() {
	TabTitle('Suche Iglowanie');
	return (
		<>
			<MainIglowanie />
			<Footer />
		</>
	);
}

export default Iglowanie;
