import React, { useEffect } from 'react';
import SectionTitle from './utilis/SectionTitle';
import './MainAcl.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function MainAcl() {
	useEffect(() => {
		AOS.init({
			duration: 2500,
		});
		// AOS.refresh();
	}, []);
	return (
		<>
			<div className='container'>
				<SectionTitle heading='ACL' subheading='' />
				<div className='acl'>
					<p className='acl__h3' data-aos={'fade-right'}>
						Fizjoterapia prowadzona bezpośrednio po artroskopowej rekonstrukcji
						więzadła krzyżowego przedniego ma na celu powrót do prawidłowej
						ruchomości w stawie kolanowym, uzyskanie stabilności stawu
						kolanowego oraz odzyskanie siły mięśniowej kończyny dolnej. Wczesne
						wprowadzenie ćwiczeń zapobiega tworzeniu się zrostów pooperacyjnych
						oraz konfliktowi w stawie rzepkowo-udowym.<br/> Przygotowany program
						usprawniania łączy konsultację fizjoterapeutyczną z zastosowaniem
						aplikacji prezentującej rehabilitację w pierwszych 12 tygodniach po
						zabiegu ACL.<br/> Pojawiające się wątpliwości będą konsultowane
						indywidualnie z fizjoterapeutą poprzez wizytę w gabinecie lub
						telefonicznie.
					</p>
					<div className='acl__img'></div>
                    <h4 className='acl__h3' data-aos={'fade-right'}>Usprawnianie po artroskopowej rekonstrukcji więzadła krzyżowego przedniego stawu kolanowego​</h4>
                    <p className='acl__text'>Zasady postępowania w pierwszych 2 tygodniach po artroskopowej rekonstrukcji ACL.</p>
                    <p className='acl__text'>Należy zastosować zasadę <span className='acl__bold'>PRICE</span>.</p>
                    <ol className='acl__lista'>
                        <li className='acl__items' data-aos={'zoom-in'}> <span className='acl__bold acl__bg'>Protection</span> – Odciążenie. Zastosowanie kul łokciowych. Nauka chodu według wzorca trójtaktowego w kolejności: 2 kule, kończyna operowana ( stopa oparta o podłoże bez obciążania), kończyna zdrowa . Ciężar ciała opieraj podczas przenoszenia i stawiania kończyny operowanej na kulach i kończynie zdrowej. Stawiaj małe kroki nie przekraczając linii między podstawą kul.</li>
                        <li className='acl__items' data-aos={'zoom-in'}><span className='acl__bold acl__bg'>Rest</span> – Odpoczynek. Przebywanie w pozycji pionowej oraz chodzenie powinno trwać nie dłużej niż 2-3 godz. dziennie.</li>
                        <li className='acl__items' data-aos={'zoom-in'}><span className='acl__bold acl__bg'>Ice</span> – Lód. Zastosowanie lodu lub żeli chłodzących na staw kolanowy co 2-3 godz.</li>
                        <li className='acl__items' data-aos={'zoom-in'}><span className='acl__bold acl__bg'>Compresion</span> – Ucisk. Stosowanie opaski uciskowej na staw kolanowy. Ucisk powinien być mocniejszy poniżej stawu kolanowego, słabszy powyżej w celu likwidacji obrzęku.</li>
                        <li className='acl__items' data-aos={'zoom-in'}><span className='acl__bold acl__bg'>Elevation</span> – Elewacja. Przebywanie w pozycji leżącej oraz uniesienie kończyny powyżej klatki piersiowej co 2-3 godz. przez 30 min równocześnie ze schładzaniem stawu kolanowego.</li>
                    </ol>
                    <p className='acl__text'>Zalecany zakres ruchu w stawie kolanowym po 2 tygodniach ćwiczeń usprawniających:</p>
                    <ul className='acl__lista'>
                        <li className='acl__item'>zgięcie stawu kolanowego 90°</li>
                        <li className='acl__item'>wyprost stawu kolanowego 0°</li>
                    </ul>
                    <p className='acl__text' data-aos={'fade-right'}><span>Stosować ćwiczenia według przygotowanego programu usprawniania.</span></p>
                    <p className='acl__text'>Ćwiczenia wykonuj codziennie bez stabilizatora, pojawienie się bólu i obrzęku jest sygnałem do ograniczenia ćwiczeń oraz konsultacji z lekarzem lub fizjoterapeutą.</p>
				</div>
			</div>
		</>
	);
}
