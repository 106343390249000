import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './SectionTitle.css';

export default function SectionTitle({
	subheading = 'Need Subheading',
	heading = 'need heading',
}) {
	useEffect(() => {
		AOS.init({
			duration : 2500
		});
		// AOS.refresh();
	}, []);
	return (
		<div className='section-title'>
			<div className='top leftBorder'></div>
			<div className='top rightBorder'></div>
			<div className='bottom rightBorder'></div>
			<div className='bottom leftBorder'></div>
			<p data-aos={'fade-right'} className='section-p'>
				{subheading}
			</p>
			<h2 data-aos={'zoom-in'} className='section-h2'>{heading}</h2>
		</div>
	);
}
