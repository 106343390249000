import React from 'react';
import '../../App.css';
import MainKinesiotaping from '../MainKinesiotaping';
import Footer from '../Footer';
import TabTitle from '../utilis/TabTitle';

function Kinesiotaping() {
	TabTitle('Kinesiotaping');
	return (
		<>
			<MainKinesiotaping />
			<Footer />
		</>
	);
}

export default Kinesiotaping;
