import React from 'react';
import '../../App.css';
import MainError from '../MainError';
import Footer from '../Footer';
import TabTitle from '../utilis/TabTitle';

function Error() {
	TabTitle('404 Error');
	return (
		<>
			<MainError/>
			<Footer />
		</>
	);
}

export default Error;
