import React, { useState } from 'react';
import './ContactForm.css';

export default function ContactForm() {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	return (
		<>
			<form
				action='https://formsubmit.co/305ecff116b75bc73bd515114a5a4489'
				method='POST'>
				{/* <input type='hidden' name='_captcha' value='false'/> */}
				<input
					type='hidden'
					name='_subject'
					value='E-mail ze strony NZOZ Fizjo-Medica'
				/>
				<input
					type='hidden'
					name='_next'
					value='https://www.fizjo-medica.eu/thankyou'
				/>
				<div className='form-group'>
					<input
						type='text'
						id='name'
						name='name'
						placeholder='Imię'
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
				<div className='form-group'>
					<input
						type='email'
						id='email'
						name='email'
						placeholder='Email'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required
					/>
				</div>
				<div className='form-group'>
					<textarea
						type='text'
						id='message'
						name='message'
						placeholder='Wiadomość'
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						required
					/>
				</div>
				<button type='submit'>Wyślij</button>
			</form>
		</>
	);
}
