import React from 'react';
import '../../App.css';
import MainFizykoterapia from '../MainFizykoterapia';
import Footer from '../Footer';
import TabTitle from '../utilis/TabTitle';

function Fizykoterapia() {
	TabTitle('Fizykoterapia');
	return (
		<>
			<MainFizykoterapia />
			<Footer />
		</>
	);
}

export default Fizykoterapia;
