import React from 'react';
import SectionTitle from './utilis/SectionTitle';
import './Offer.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import { useNavigate } from 'react-router-dom';

export default function Offer(props) {
	let navigate = useNavigate();
	return (
		<>
			<div className='offer__wrapper'>
				<Card className='mx-5 cardOffer'>
					<Card.Img
						className='img__offer'
						variant='top'
						src={`../images/${props.item.coverImgSmall}`}
						alt='ćwiczenia'
						loading="lazy"
						// srcSet={`../images/${props.item.coverImgSmall} 300w,../images/${props.item.coverImg} 1280w`}
						// sizes='(max-width: 300px) 300px, (max-width: 1280px)'
					/>
					<Card.Body className='d-flex flex-column justify-content-center align-items-center py-2'>
						{props.item.text ? <Card.Title className='cardTitle'>{props.item.title}</Card.Title> : <Card.Title className='cardTitleTwo'>{props.item.title}</Card.Title>}
						<Card.Text className='cardText'>{props.item.text}</Card.Text>
						<Button
							variant='outline-secondary'
							onClick={() => {
								navigate(props.item.link);
							}}>
							Zobacz więcej
						</Button>
					</Card.Body>
				</Card>
			</div>
		</>
	);
}
