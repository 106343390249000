import React, { useEffect } from 'react';
import SectionTitle from './utilis/SectionTitle';
import './MainTkanki.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function MainMasaz() {
	useEffect(() => {
		AOS.init({
			duration: 2500,
		});
		// AOS.refresh();
	}, []);
	return (
		<>
			<div className='container'>
				<SectionTitle heading='Masaż tkanek głębokich' subheading='' />
				<div className='tkanki'>
					<div className='tkanki__img'></div>
					<p className='tkanki__h3' data-aos={'fade-right'}>
						Masaż tkanek głębokich, zwany również masażem głębokim opiera się na
						warstwowej pracy tkankowej, która prowadzi do rozluźnienia
						powstających nieprawidłowych napięć w ciele człowieka. Jako
						narzędzie pracy stosuje się przedramię, łokieć, pięść, paliczki,
						opuszki palców czy nasadę ręki. Każdy ruch wykonany jest bardzo
						powoli, z dużą precyzją i odpowiednio dobraną siłą nacisku. Masaż
						tkanek głębokich jest bardzo skuteczny i efektywny a jego rezultaty
						zauważalne są już po pierwszej sesji. Polecany jest głównie w
						przypadku powszechnych dolegliwości i urazów, takich jak:
					</p>
					<ul className='tkanki__lista'>
						<li className='tkanki__item' data-aos={'zoom-in-up'}>
							zmniejszona ruchomość stawów,
						</li>
						<li className='tkanki__item' data-aos={'zoom-in-up'}>
							przewlekły ból mięśniowy,
						</li>
						<li className='tkanki__item' data-aos={'zoom-in-up'}>
							zespół bólowy kręgosłupa,
						</li>
						<li className='tkanki__item' data-aos={'zoom-in-up'}>
							łokieć tenisisty, golfisty,
						</li>
						<li className='tkanki__item' data-aos={'zoom-in-up'}>
							wady postawy,
						</li>
						<li className='tkanki__item' data-aos={'zoom-in-up'}>
							przykurcz ścięgien i mięśni,
						</li>
						<li className='tkanki__item' data-aos={'zoom-in-up'}>
							zapalenie rozcięgna podeszwowego,
						</li>
						<li className='tkanki__item' data-aos={'zoom-in-up'}>
							rwa kulszowa, barkowa,
						</li>
						<li className='tkanki__item' data-aos={'zoom-in-up'}>
							napięciowe bóle głowy
						</li>
					</ul>
				</div>
			</div>
		</>
	);
}
