import React, { useEffect } from 'react';
import SectionTitle from './utilis/SectionTitle';
import './MainKinezyterapia.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function MainKinezyterapia() {
	useEffect(() => {
		AOS.init({
			duration: 2500,
		});
		// AOS.refresh();
	}, []);
	return (
		<>
			<div className='container'>
				<SectionTitle heading='Kinezyterapia' subheading='' />
				<div className='kinezyterapia'>
					<p className='kinezyterapia__h3' data-aos={'fade-right'}>
						Kinezyterapia obejmuje całość zagadnień związanych z leczeniem
						ruchem. Podstawą kinezyterapii są ćwiczenia o charakterze
						leczniczym. Kinezyterapia ma za zadanie przywrócenie pełnej
						sprawności fizycznej, jeśli jest to możliwe w danym schorzeniu lub
						maksymalnej sprawności fizycznej w przewlekłym schorzeniu.
					</p>
					<div className='kinezyterapia__img'></div>
				</div>
			</div>
		</>
	);
}
