import React, { useEffect } from 'react';
import SectionTitle from './utilis/SectionTitle';
import './GiftHeader.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Price() {
	useEffect(() => {
		AOS.init({
			duration: 2500,
		});
		// AOS.refresh();
	}, []);

	return (
		<>
			<div className='container'>
				<SectionTitle heading='wyjątkowy prezent' subheading='' />
				<div className='gift__wrapper'>
					<div className='thumb'>
						<a href='#'>
							<span className='gift__text'>
								Zadbaj o siebie oraz bliskich <i className='far fa-heart'></i>
							</span>
						</a>
					</div>
					<div className='gift__desc'  data-aos={"zoom-in-up"}>
						<p className='gift__p'>
							Bon podarunkowy na wybrane zabiegi w naszym gabinecie to wspaniały
							prezent na każdą okazję, który nie jednemu sprawi ogromną
							przyjemność. Bon jest w formie eleganckiego zaproszenia, do wyboru
							w <span className='gift__light'>3</span><span className='gift__regular'>różnych</span><span className='gift__dark'>odcieniach</span>. Kosztuje tyle samo, co standardowe usługi
							wybranych zabiegów. Adresat bonu podarunkowego sam wybiera dogodny
							dla siebie termin zabiegu. W celu zamówienia bonu podarunkowego
							prosimy o kontakt telefoniczny lub osobiście w gabinecie.
						</p>
					</div>
				</div>
			</div>
		</>
	);
}
