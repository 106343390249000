export const Zabiegi = [
	{
		id: 1,
		title: 'PRĄD GALWANICZNY',
		desc: 'Jest to prąd stały, powoduje zmniejszenie pobudliwości nerwów, przekrwienie naczyń, usprawnienie krążenia obwodowego.',
	},
	{
		id: 2,
		title: 'JONOFOREZA',
		desc: 'To zabieg polegający na wprowadzeniu do tkanek siłami pola elektrycznego jonów działających leczniczo.',
	},
	{
		id: 3,
		title: 'PRĄDY DIADYNAMICZNE',
		desc: 'Są to prądy impulsowe małej częstotliwości mające działanie przeciwbólowe, obniżające napięcie mięśni, poprawiające trofikę skóry, pobudzające do skurczu mięśnie szkieletowe.',
	},
	{
		id: 4,
		title: 'ELEKTROSTYMULACJA',
		desc: 'Ma zastosowanie w porażeniu wiotkim nerwu: promieniowego, strzałkowego, piszczelowego, twarzowego.',
	},
	{
		id: 5,
		title: 'PRĄDY KOTZ A',
		desc: 'Służą do wzmacniania siły mięśniowej i zwiększania masy mięśni.',
	},
	{
		id: 6,
		title: 'PRĄDY INTERFERENCYJNE',
		desc: 'To prądy średniej częstotliwości działające przeciwbólowo, powodujące podwyższenie progu bólu, pobudzają do skurczu mięśnie szkieletowe.',
	},
	{
		id: 7,
		title: 'PRĄD TRABERTA',
		desc: 'Jest to przerywany prąd galwaniczny działający bodźcowo, zmniejszający odczucie bólu.',
	},
	{
		id: 8,
		title: 'TENS',
		desc: 'To przezskórna elektryczna stymulacja nerwów, stosowana do leczenia bólu objawowego oraz w przewlekłych zespołach bólowych.',
	},
	{
		id: 9,
		title: 'ULTRADŹWIĘKI',
		desc: 'Fala ultradźwiękowa ma zastosowanie w chorobie zwyrodnieniowej stawów, zespołach bólowych stawów, ostrodze piętowej, łokciu tenisisty.',
	},
	{
		id: 10,
		title: 'POLE MAGNETYCZNE NISKIEJ CZĘSTOTLIWOŚCI',
		desc: 'Zmienne pole magnetyczne stosujemy w chorobie zwyrodnieniowej stawów, złamaniach,  urazach więzadeł, mięśni, stawów, trudno gojących się ranach, zaburzeniach krążenia obwodowego.',
	},
	{
		id: 11,
		title: 'KRIOTERAPIA',
		desc: 'Polega na zastosowaniu ciekłego azotu w leczeniu zespołów bólowych kręgosłupa i stawów obwodowych, skręceniach, zwichnięciach, przykurczach w stawach, chorobie Parkinsona, SM.',
	},
	{
		id: 12,
		title: 'LASEROTERAPIA',
		desc: 'Wiązkę laserową stosujemy w leczeniu trudno gojących się ran, przewlekłych stanach zapalnych, chorobach zwyrodnieniowych stawów, utrudnionym zroście kostnym, zapaleniach ścięgien, stanach zapalnych zatok.',
	},
	{
		id: 13,
		title: 'SOLLUX',
		desc: 'To zabieg polegający na emisji promieniowania podczerwonego powoduje zmniejszenie napięcia mięśni, podwyższenie progu odczuwania bólu, rozszerzenie naczyń włosowatych skóry.',
	},
];
