import React from 'react';
import '../../App.css';
import MainUsg from '../MainUsg';
import Footer from '../Footer';
import TabTitle from '../utilis/TabTitle';

function Usg() {
	TabTitle('USG');
	return (
		<>
			<MainUsg />
			<Footer />
		</>
	);
}

export default Usg;
