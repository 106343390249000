import React from 'react';
import '../../App.css';
import MainTkanki from '../MainTkanki';
import Footer from '../Footer';
import TabTitle from '../utilis/TabTitle';

function Tkanki() {
	TabTitle('Masaż Tkanek Głębokich');
	return (
		<>
			<MainTkanki />
			<Footer />
		</>
	);
}

export default Tkanki;
