import React, { useEffect, useRef } from 'react';
import '../App.css';
import './HeroSectionn.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';

import SectionTitle from './utilis/SectionTitle';

function HeroSection() {
	let navigate = useNavigate();

	const aboutSection = useRef(null);
	const scrollDown = (ref) => {
		window.scrollTo({
			top: ref.current.offsetTop,
			behavior: 'smooth',
			speed: 10000,
		});
	};

	useEffect(() => {
		AOS.init({
			duration: 3000,
		});
	}, []);
	return (
		<>
			<div className='hero_container' loading="eager">
				<div className='hero_title'>
					<h1 className='hero_h1' data-aos={'zoom-in'}>
						NZOZ Fizjo-Medica
					</h1>
					<p className='hero_p' data-aos={'zoom-in'}>
						Umowa z NFZ
					</p>
				</div>
				<div className='hero_desc'>
				<div className="hero_desc-icon"><i className='fas fa-angle-double-right'></i></div>
					<h3
						className='hero_h3 hero_h3-right'
						onClick={() => {
							navigate('/uslugi');
						}}
						data-aos={'fade-right'}>
						Rehablilitacja ambulatoryjna
					</h3>
					<h3
						className='hero_h3'
						onClick={() => {
							navigate('/w-domu');
						}}
						data-aos={'fade-left'}>
						Rehabilitacja w domu pacjenta{' '}
					</h3><div className="hero_desc-icon"><i className='fas fa-angle-double-left'></i></div>
				</div>

				<a
					href='#'
					onClick={() => scrollDown(aboutSection)}
					className='hero_link'>
					<div className='hero_arrow'>
						<img className='hero_icon' src='images/arrow.svg' alt='' />
					</div>
				</a>
			</div>
			<div className='container' ref={aboutSection}>
				<SectionTitle
					heading='NZOZ Fizjo-Medica'
					subheading='co nas wyróżnia'
				/>
				<div className='about__wrapper'>
					Czy dokuczają Ci bóle mięśni? Doznałeś kontuzji lub jesteś po wypadku,
					po którym potrzebna Ci profesjonalna i przede wszystkim skuteczna
					rehabilitacja? Z naszym zespołem szybko dojdziesz do siebie!
					<br /> Jesteśmy doświadczonymi fizjoterapeutami. Pomaganie Pacjentom
					to nasza pasja. Stosujemy różnorodne techniki i metody, dzięki temu
					możemy Ci pomóc w wielu dolegliwościach. Szkolimy się z nowszych
					technik, aby mieć pewność, że będziemy mogli Tobie pomóc. Do każdego
					pacjenta podchodzimy indywidualnie, poświęcając czas na dokładne
					przeanalizowanie dolegliwości jak i dobranie odpowiedniej terapii.
					<br />{' '}
					<span className='about__bold'>
						Nasz gabinet fizjoterapii znajduje się w Tyczynie, przy ulicy
						Tyczynera 2a, jeśli pacjent nie jest w stanie przyjechać do nas
						oferujemy wizyty domowe!
						<br />
						Zapraszamy!
					</span>
				</div>
			</div>
		</>
	);
}

export default HeroSection;
