import React from 'react';
import '../../App.css';
import MainAcl from '../MainAcl';
import Footer from '../Footer';
import TabTitle from '../utilis/TabTitle';

function Acl() {
	TabTitle('ACL');
	return (
		<>
			<MainAcl />
			<Footer />
		</>
	);
}

export default Acl;
