import React, { useEffect } from 'react';
import SectionTitle from './utilis/SectionTitle';
import './MainKinesiotaping.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function MainKinesiotaping() {
	useEffect(() => {
		AOS.init({
			duration: 2500,
		});
		// AOS.refresh();
	}, []);
	return (
		<>
			<div className='container'>
				<SectionTitle heading='Kinesiotaping' subheading='' />
				<div className='kinesiotaping'>
					<p className='kinesiotaping__h3' data-aos={'fade-right'}>
                    Kinesiotaping jest to metoda terepautyczna polegająca na oklejaniu wybranych fragmentów ciała plastrami o specjalnej strukturze. Pomocna w terapii bólu, korekcji układu powięziowego oraz reedukowaniu czynności ruchowych.
					</p>
					<div className='kinesiotaping__img'></div>
				</div>
			</div>
		</>
	);
}
