import React from 'react';
import '../../App.css';
import HeroSectionn from '../HeroSectionn';
import Footer from '../Footer';
import TabTitle from '../utilis/TabTitle';

function Home() {
	TabTitle('NZOZ Fizjo-Medica');
	return (
		<>
			<HeroSectionn />
			<Footer />
		</>
	);
}

export default Home;
