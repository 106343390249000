import React from 'react';
import '../../App.css';
import Offer from '../Offer';
import Footer from '../Footer';
import data from '../../data.js';
import SectionTitle from '../utilis/SectionTitle';
import TabTitle from '../utilis/TabTitle';


export default function Contact() {
	TabTitle('Usługi');	
	const info = data.map((item) => {
		return <Offer key={item.id} item={item} />;
	});
	return (
		<>
			<div className='container'>
				<SectionTitle heading='Oferowane zabiegi' subheading='umowa z NFZ' />
				<div className='uslugi__wrapper'>{info}</div>
			</div>
			<Footer />
		</>
	);
}
