import React, { useEffect } from 'react';
import SectionTitle from './utilis/SectionTitle';
import './Elements.css';
import './Price.css';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Price() {
	let navigate = useNavigate();
	useEffect(() => {
		AOS.init({
			duration: 2500,
		});
		// AOS.refresh();
	}, []);

	return (
		<>
			<div className='container'>
				<SectionTitle heading='Cennik usług prywatnych' subheading='' />
				<div className='gift__wrapper' onClick={() => {
							navigate('/bon');
						}}>
					<h3
						className='cennik__h3'>
						Bon Podarunkowy - Wyjątkowy Prezent{' '}
						<i className='cennik__green fas fa-hand-holding-heart'></i>
					</h3>
				</div>
				<div className='cennik__wrapper'>
					<h2 className='cennik__heading'>Fizykoterapia</h2>
					<table className='table table-striped'>
						<thead>
							<tr>
								<th scope='col'>Usługa</th>
								<th scope='col'>Cena</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Laseroterapia</td>
								<td>20zł</td>
							</tr>
							<tr>
								<td>Ultradźwięki</td>
								<td>15zł</td>
							</tr>
							<tr>
								<td>Jonoforeza</td>
								<td>15zł</td>
							</tr>
							<tr>
								<td>Prądy kotz'a, traberta, tens</td>
								<td>15zł</td>
							</tr>
							<tr>
								<td>Elektrostymulacja</td>
								<td>30zł</td>
							</tr>
							<tr>
								<td>Fonoforeza</td>
								<td>15zł</td>
							</tr>
							<tr>
								<td>Pole magnetyczne niskiej częstotliwosci</td>
								<td>15zł</td>
							</tr>
							<tr>
								<td>Krioterapia z zastosowaniem ciekłego azotu</td>
								<td>25zł</td>
							</tr>
							<tr>
								<td>Prąd galwaniczny</td>
								<td>15zł</td>
							</tr>
							<tr>
								<td>Terapia skojarzona</td>
								<td>25zł</td>
							</tr>
							<tr>
								<td>Prady interferencyjne</td>
								<td>15zł</td>
							</tr>
							<tr>
								<td>Prady diadynamiczne</td>
								<td>15zł</td>
							</tr>
							<tr>
								<td>Sollux</td>
								<td>15zł</td>
							</tr>
							<tr>
								<td>Fala uderzeniowa</td>
								<td>70zł</td>
							</tr>
						</tbody>
					</table>
					<h2 className='cennik__heading'>Kinezyterapia</h2>
					<table className='table table-striped'>
						<thead>
							<tr>
								<th scope='col'>Zabieg</th>
								<th scope='col'>Cena</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Ćwiczenia w odciążeniu</td>
								<td>15zł</td>
							</tr>
							<tr>
								<td>Gimnastyka indywidualna</td>
								<td>80zł</td>
							</tr>
							<tr>
								<td>PNF, McKenzie, i inne</td>
								<td>80zł</td>
							</tr>
							<tr>
								<td>Rowerek</td>
								<td>15zł</td>
							</tr>
							<tr>
								<td>Suche igłowanie</td>
								<td>100zł</td>
							</tr>
							<tr>
								<td>Terapia stawów skroniowo-żuchwowych</td>
								<td>100zł</td>
							</tr>
							<tr>
								<td>Konsultacja fizjoterapeutyczna</td>
								<td>70zł</td>
							</tr>
							<tr>
								<td>Kinesiotaping</td>
								<td>40-80zł</td>
							</tr>
						</tbody>
					</table>
					<h2 className='cennik__heading'>Masaż leczniczy</h2>
					<table className='table table-striped'>
						<thead>
							<tr>
								<th scope='col'>Zabieg</th>
								<th scope='col'>Cena</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Masaż ręczny całkowity</td>
								<td>120zł</td>
							</tr>
							<tr>
								<td>Masaż ręczny częściowy</td>
								<td>100zł</td>
							</tr>
							<tr>
								<td>Drenaż limfatyczny</td>
								<td>100zł</td>
							</tr>
							<tr>
								<td>Masaż tkanek głębokich</td>
								<td>100zł</td>
							</tr>
							<tr>
								<td>Masaż wirowy kończyn górnych</td>
								<td>30zł</td>
							</tr>
							<tr>
								<td>Masaż wirowy kończyn dolnych</td>
								<td>40zł</td>
							</tr>
						</tbody>
					</table>
					<h2 className='cennik__heading'>USG</h2>
					<table className='table table-striped'>
						<thead>
							<tr>
								<th scope='col'>Zabieg</th>
								<th scope='col'>Cena</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>USG (jednego stawu)</td>
								<td>200zł</td>
							</tr>
						</tbody>
					</table>
					<h2 className='cennik__heading'>Rehablilitacja środowiskowa</h2>
					<table className='table table-striped'>
						<thead>
							<tr>
								<th scope='col'>Zabieg</th>
								<th scope='col'>Cena</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Wizyta fizjoterapeutyczna w domu pacjenta</td>
								<td>80-150zł</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
}
