import React from 'react';
import ContactForm from './ContactForm';
import ContactInfoItem from './ContactInfoItem';
import SectionTitle from './utilis/SectionTitle';
import './ContactSection.css';

export default function ContactSection() {
	return (
		<>
			<div className='container'>
				<SectionTitle heading='kontakt' subheading='skontaktuj się z nami' />
				<div className='contactSection__wrapper'>
					<div className='left'>
						<ContactInfoItem icon={<i className='fas fa-phone-alt'></i>} style={{maxWidth: 160}} text='17 230 22 82  791 389 626' />
						<ContactInfoItem icon={<i className='far fa-envelope'></i>} text='nzoz@fizjo-medica.eu' />
						<ContactInfoItem text='Tycznera 2a, 36-020 Tyczyn' />
					</div>
					<div className='right'>
						<ContactForm />
					</div>
				</div>
			</div>
		</>
	);
}
