import React from 'react';
import SectionTitle from './utilis/SectionTitle';
import './MainError.css';
import { useNavigate } from 'react-router-dom';

export default function MainAcl() {
	let navigate = useNavigate();
	return (
		<>
			<div className='container'>
				<SectionTitle heading='Dziękujemy!' subheading='' />
				<div className='error'>
					<h4 className='error__h4'>
						By powrócić na stornę główną kliknij w{' '}
						<a className='error__a'
							onClick={() => {
								navigate('/');
							}}>
							link
						</a>
						.
					</h4>
				</div>
			</div>
		</>
	);
}
