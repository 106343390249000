import './Info.css';

const Info = () => {
	return (
		<>
			<nav className='info'>
				<div className='info-container'>
					<div className='info-right'>
						<div>
							<p className='info-tel'>
								<i className='fas fa-phone-alt'></i> 17 230 22 82 || 791 389 626
							</p>
						</div>
						<div>
							<p className='info-email'>
								<i className='far fa-envelope'></i> nzoz@fizjo-medica.eu
							</p>
						</div>
						<a
							href='https://www.facebook.com/fizjomedicarehabilitacja'
							target='_blank'
							className='info-social'>
							<i className='fab fa-facebook'></i>
						</a>
					</div>
				</div>
			</nav>
		</>
	);
};

export default Info;
