import React from 'react';
import './Footer.css';

function Footer() {
	return (
		<div className='footer-container'>
			<hr/>
			<p className='footer-text'>&copy; {new Date().getFullYear()} NZOZ Fizjo-Medica</p>
		</div>
	);
}

export default Footer;
