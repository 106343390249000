import React from 'react';
import '../../App.css';
import MainThank from '../MainThank';
import Footer from '../Footer';
import TabTitle from '../utilis/TabTitle';

function ThankYou() {
	TabTitle('Dziękujemy');
	return (
		<>
			<MainThank/>
			<Footer />
		</>
	);
}

export default ThankYou;
