import React from 'react';
import ContactSection from '../ContactSection';
import Map from '../Map';
import '../../App.css';
import Footer from '../Footer';
import TabTitle from '../utilis/TabTitle';
import { Helmet } from 'react-helmet-async';

export default function Contact() {
  TabTitle('Kontakt');
  return (
    <>
    <Helmet>
				{/* <!-- HTML Meta Tags --> */}
				<title>Kontakt</title>
				<link rel='canonical' href='https://www.fizjo-medica.eu/kontakt' />
				<meta name='description' content='Kontakt' data-react-helmet='true' />
				<meta
					name='keywords'
					content='fizjoterapia, tyczyn, NFZ, zabiegi, USG, rehabilitacja, suche igłowanie, ćwiczenia, masaż, zdrowie, kontakt'
					data-react-helmet='true'
				/>

				{/* <!-- Google / Search Engine Tags --> */}
				<meta
					itemprop='description'
					content='Kontakt'
					data-react-helmet='true'
				/>
				<meta
					itemprop='keywords'
					content='fizjoterapia, tyczyn, NFZ, zabiegi, USG, rehabilitacja, suche igłowanie, ćwiczenia, masaż, zdrowie, kontakt'
					data-react-helmet='true'
				/>
			</Helmet>
      <ContactSection />
      <Map />
      <Footer />
    </>
  );
}