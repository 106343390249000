import React from 'react';
import '../../App.css';
import MainKinezyterapia from '../MainKinezyterapia';
import Footer from '../Footer';
import TabTitle from '../utilis/TabTitle';

function Kinezyterapia() {
	TabTitle('Kinezyterapia');
	return (
		<>
			<MainKinezyterapia />
			<Footer />
		</>
	);
}

export default Kinezyterapia;
