import React from 'react';
import '../../App.css';
import MainTerapia from '../MainTerapia';
import Footer from '../Footer';
import TabTitle from '../utilis/TabTitle';

function Terapia() {
	TabTitle('Terapia Stawów Skroniowo-Żuchwowych');
	return (
		<>
			<MainTerapia />
			<Footer />
		</>
	);
}

export default Terapia;
