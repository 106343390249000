import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Elements from '../Elements';
import TabTitle from '../utilis/TabTitle';


export default function Contact() {
	TabTitle('Pierwsza wizyta')
	return (
		<>
			<Elements />
			<Footer />
		</>
	);
}
