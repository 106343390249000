import React, { useEffect } from 'react';
import SectionTitle from './utilis/SectionTitle';
import './MainTerapia.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function MainMasaz() {
	useEffect(() => {
		AOS.init({
			duration: 2500,
		});
		// AOS.refresh();
	}, []);
	return (
		<>
			<div className='container'>
				<SectionTitle
					heading='Terapia stawów skroniowo-żuchwowych'
					subheading=''
				/>
				<div className='terapia'>
					<h3 className='terapia__h3' data-aos={'fade-right'}>
						Rehabilitacja stawów skroniowo – żuchwowych:
					</h3>
					<p className='terapia__p'>
						Stosowane leczenie patologii w obrębie stawów skroniowo-żuchwowych
						opiera się na kompleksowym podejściu do pacjenta. Pozwala to
						zdecydowanie szybciej i bardziej efektywnie pozbyć się dysfunkcji.
						Podstawą leczenia jest przeprowadzenie wnikliwego wywiadu oraz
						badanie pacjenta, a także stworzenie planu postępowania.<br/> Polega ona
						w głównej mierze na rozluźnianiu mięśni układu stomatologicznego.
						Ważnym elementem rehabilitacji jest edukacja pacjenta oraz nauka
						ćwiczeń wykonywanych w domu.
					</p>
					<div className='terapia__items'>
						<ul className='terapia__lista'>
						<p className='terapia__p terapia__bold'>Cel terapii:</p>
							<li className='terapia__item' data-aos={'zoom-in-up'}>
                            uzyskanie prawidłowej ruchomości stawów skroniowo- żuchwowych,
							</li>
							<li className='terapia__item' data-aos={'zoom-in-up'}>
                            działanie przeciwbólowe w okolicy głowy i szyi,
							</li>
							<li className='terapia__item' data-aos={'zoom-in-up'}>
                            rozluźnienie napięcia mięśniowego w obrębie twarzy i czaszki oraz kręgosłupa szyjnego
							</li>
						</ul>
						<ul className='terapia__lista'>
						<p className='terapia__p terapia__bold'>Zastosowanie terapii:</p>
							<li className='terapia__item' data-aos={'zoom-in-up'}>
                            po zabiegach chirurgicznych w obrębie twarzoczaszki,
							</li>
							<li className='terapia__item' data-aos={'zoom-in-up'}>
                            w wyniku zbyt dużego stresu emocjonalnego dochodzi do nasilonego napięcia mięśni,
							</li>
							<li className='terapia__item' data-aos={'zoom-in-up'}>
                            niewłaściwe ustawienie głowy (protrakcyjne – gdy głowa przemieszcza się do przodu przesuwając środek ciężkości), niewłaściwa postawa ciała,
							</li>
							<li className='terapia__item' data-aos={'zoom-in-up'}>
                            zaburzenia związane z nieprawidłowościami rozwoju twarzowo-czaszkowego,
							</li>
							<li className='terapia__item' data-aos={'zoom-in-up'}>
                            przebyte infekcje i stany zapalne: RZS, zapalne ucha środkowego, świnka, choroba zwyrodnieniowa stawów,
							</li>
							<li className='terapia__item' data-aos={'zoom-in-up'}>
                            po zabiegach onkologicznych,
							</li>
							<li className='terapia__item' data-aos={'zoom-in-up'}>
                            po urazach mechanicznych w obrębie kręgosłupa szyjnego, głowy, twarzy: komunikacyjnych, w wyniku upadku, uderzeniu w podbródek, które mogło spowodować zwichnięcie krążka i zablokowanie stawu,
							</li>
							<li className='terapia__item' data-aos={'zoom-in-up'}>
                            u osób z bruksizmem (częste zgrzytanie i zaciskanie zębów)
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
}
