import React, {useEffect} from 'react';
import './Map.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Map() {
	useEffect(() => {
		AOS.init({
			duration : 2500
		});
		// AOS.refresh();
	}, []);
	return (
		<div>
			<div className='containerMap'>
				<h3  data-aos={'fade-right'} className='map__heading'>Tutaj jesteśmy</h3>
			</div>
			<div className='imgWrapper'>
				<iframe className='imgContact' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d868.9427113033205!2d22.027546784877494!3d49.96347042423127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473cf742ccc5f3f5%3A0x6dab4dca5d8382a5!2sNZOZ%20Fizjo-Medica!5e0!3m2!1spl!2spl!4v1668017315944!5m2!1spl!2spl"></iframe>
			</div>
		</div>
	);
}
