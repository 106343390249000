import React from 'react';
import '../../App.css';
import MainFala from '../MainFala';
import Footer from '../Footer';
import TabTitle from '../utilis/TabTitle';

function Fala() {
	TabTitle('Fala Uderzeniowa');
	return (
		<>
			<MainFala />
			<Footer />
		</>
	);
}

export default Fala;
