import React from 'react';
import './ContactInfoItem.css';

export default function ContactInfoItem({
	icon = <i className="fas fa-map-marker-alt"></i>,
	text = '',
	style={},
}) {
	return (
		<div className='containerIcon'>
			<div className='iconContact'>{icon}</div>
			<div className='infoContact'>
				<p className='infoContact__p' style={style}>{text}</p>
			</div>
		</div>
	);
}
