import React, {useEffect} from 'react';
import SectionTitle from './utilis/SectionTitle';
import './Elements.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Elements() {
    useEffect(() => {
		AOS.init({
			duration : 2500
		});
		// AOS.refresh();
	}, []);
	return (
		<>
			<div className='container'>
				<SectionTitle
					heading='przed pierwszą wizytą'
					subheading='co przygotować'
				/>
				<div className='elements__wrapper'>
					<div className='elements'>
						<div className='elements__cards elements__cards--one'>
							<h4 className='text-about'><span>Dokumentacja</span> medyczna</h4>
                            <div className='square'></div>
							<p className='lorem'>
								Jeśli posiadasz dokumentację medyczną jak RTG, USG, wyniku
								rezonansu magnetycznego, tomografii komputerowej, wypisów ze
								szpitali itd. to weź ją ze sobą – pomoże nam to w diagnozowaniu
								i przygotowaniu rehabilitacji. Podpis lekarza - jeśli na NFZ.
							</p>
							<hr />
						</div>
						<div className='elements__cards elements__cards--two'>
							<h4 className='text-about'><span>Skierowanie</span></h4>
							<p className='lorem'>
								Jeśli na NFZ, wymagane jest skierowanie, jeśli prywatnie, nie
								potrzebujesz skierowania.
							</p>
							<hr />
						</div>
						<div className='elements__cards elements__cards--three'>
							<h4 className='text-about'><span>Wygodny</span>ubiór i obuwie na zmianę</h4>
							<p className='lorem'>
								Sportowy strój, pozwoli na swobodne wykonanie terapii.
							</p>
							<hr />
						</div>
						<div className='elements__cards elements__cards--four'>
							<h4 className='text-about'>Bądź na <span>czas</span></h4>
							<p className='lorem'>
								Najlepiej przyjść kilka minut wcześniej by złapać oddech.
							</p>
							<hr />
						</div>
						<div className='elements__cards elements__cards--five'>
							<h4 className='text-about'>
								Nie masz możliowści przyjść na wyznaczony <span>termin</span>?
							</h4>
							<p className='lorem'>
								Daj nam znać - telefonicznie lub przez e-mail!
							</p>
							<hr />
						</div>
						<div className='elements__icon elements__cards--six'>
							<i className='far fa-newspaper elementIcon' data-aos={'zoom-in'}></i>
						</div>
						<div className='elements__icon elements__cards--seven'>
							<i className='far fa-sticky-note elementIcon' data-aos={'fade-right'}></i>
						</div>
						<div className='elements__icon elements__cards--eight'>
							<i className='fas fa-running elementIcon' data-aos={'zoom-in'}></i>
						</div>
						<div className='elements__icon elements__cards--nine'>
							<i className='far fa-clock elementIcon' data-aos={'fade-right'}></i>
						</div>
						<div className='elements__icon elements__cards--ten'>
							<i className='fas fa-phone-volume elementIcon' data-aos={'zoom-in'}></i>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
