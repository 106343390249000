import React, { useEffect } from 'react';
import '../App.css';
import './MainUsg.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SectionTitle from './utilis/SectionTitle';
import joint from '../assets/Image/knee.svg';

function MainUsg() {
	useEffect(() => {
		AOS.init({
			duration: 3000,
		});
	}, []);

	return (
		<>
			<div className='container'>
				<SectionTitle heading='USG' subheading='Diagnostyka stawów i mięśni' />
				<div className='usg'>
					<div className='usg__upperList'>
						<ul className='usg__listaOne'>
							<p className='usg__p usg__bold'>Wskazania</p>
							<li className='usg__item' data-aos={'zoom-in-up'}>
								Urazy stawów i mięśni
							</li>
							<li className='usg__item' data-aos={'zoom-in-up'}>
								Skręcenia, zwichnięcia
							</li>
							<li className='usg__item' data-aos={'zoom-in-up'}>
								Wysięki, krwiaki
							</li>
							<li className='usg__item' data-aos={'zoom-in-up'}>
								Stan zapalny stawów
							</li>
							<li className='usg__item' data-aos={'zoom-in-up'}>
								Kontrola pooperacyjna
							</li>
							<li className='usg__item' data-aos={'zoom-in-up'}>
								Zdiagnozowanie guzów, torbieli stawu
							</li>
							<li className='usg__item' data-aos={'zoom-in-up'}>
								Monitorowanie efektów leczenia stawów i mięśni
							</li>
						</ul>
						<img className='usg__icon' src={joint} alt='Staw kolanowy' />
					</div>
					<hr />
					<h4 className='usg__h4' data-aos={'slide-right'}>
						Zapraszamy do badania!
					</h4>
					<hr />
					<div className='usg__img'></div>
					<div className='usg__items'>
						<ul className='usg__lista'>
							<p className='usg__p usg__bold'>USG stawów</p>
							<li className='usg__item'>USG stawu barkowego</li>
							<li className='usg__item'>USG stawu kolanowego</li>
							<li className='usg__item'>USG stawu łokciowego</li>
							<li className='usg__item'>USG stawu biodrowego</li>
							<li className='usg__item'>USG stawu skokowego i stopy</li>
							<li className='usg__item'>USG nadgarstka i ręki</li>
						</ul>
						<ul className='usg__lista'>
							<p className='usg__p usg__bold'>USG mięśni</p>
							<p className='usg__ptext'>Ocena dynamicznej pracy i struktur mięśnia takich jak:</p>
							<li className='usg__item'>Brzusiec mięśnia</li>
							<li className='usg__item'>Połączenia mięśniowo - ścięgniste</li>
							<li className='usg__item'>Ścięgna</li>
							<li className='usg__item'>Przyczepy ścięgniste do kości</li>
							<li className='usg__item'>
								Pochewki ścięgniste, kaletki maziowe
							</li>
							<li className='usg__item'>Naczynia i nerwy</li>
							<li className='usg__item'>Powięź i tkanka podskórna</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
}

export default MainUsg;
