import React, { useEffect } from 'react';
import SectionTitle from './utilis/SectionTitle';
import './MainMasaz.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function MainMasaz() {
	useEffect(() => {
		AOS.init({
			duration: 2500,
		});
		// AOS.refresh();
	}, []);
	return (
		<>
			<div className='container'>
				<SectionTitle heading='Masaż' subheading='' />
				<div className='masaz'>
					<p className='masaz__h3' data-aos={'fade-right'}>
                    Masaż leczniczy jest formą fizycznego oddziaływania na organizm ludzki za pomocą bodźców mechanicznych, głównie pod postacią ucisku na tkanki organizmu przy biernym zachowaniu masowanego w celu złagodzenia napięcia i bólu mięśni oraz łagodzeniu napięcia psychicznego.
					</p>
					<div className='masaz__img'></div>
				</div>
			</div>
		</>
	);
}
