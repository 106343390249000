import React from 'react';
import '../../App.css';
import Wizyty from '../Wizyty';
import Footer from '../Footer';
import TabTitle from '../utilis/TabTitle';

function Domowa() {
	TabTitle('Rehabilitacja Domowa');
	return (
		<>
			<Wizyty />
			<Footer />
		</>
	);
}

export default Domowa;
