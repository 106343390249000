export default [
	{
		id: 1,
		title: 'Fizykoterapia',
		coverImg: 'fizyko-lg.jpg',
		coverImgSmall: 'fizyko-sm.jpg',
		link: '/fizykoterapia',
	},
	{
		id: 2,
		title: 'Kinezyterapia',
		coverImg: 'kinez-lg.jpg',
		coverImgSmall: 'kinez-sm.jpg',
		link: '/kinezyterapia',
	},
	{
		id: 3,
		title: 'Masaż',
		coverImg: 'massage-lg.jpg',
		coverImgSmall: 'massage-sm.jpg',
		link: '/masaz',
	},
	{
		id: 4,
		title: 'Terapia Stawów Skroniowo-Żuchwowych',
		coverImgSmall: 'stawy.jpg',
		link: '/terapia',
		text: 'Wyłącznie prywatnie',
	},
	{
		id: 5,
		title: 'Suche Igłowanie',
		coverImg: 'iglowanie-lg.jpg',
		coverImgSmall: 'iglowanie-sm.jpg',
		link: '/iglowanie',
		text: 'Wyłącznie prywatnie',
	},
	{
		id: 6,
		title: 'Masaż Tkanek Głębokich',
		coverImg: 'deepmassage-lg.jpg',
		coverImgSmall: 'deepmassage-sm.jpg',
		link: '/tkanki',
	},
	{
		id: 7,
		title: 'Fala Uderzeniowa',
		coverImg: 'fala-lg.jpg',
		coverImgSmall: 'fala-sm.jpg',
		link: '/fala',
		text: 'Wyłącznie prywatnie',
	},
	{
		id: 8,
		title: 'Kinesiotaping',
		coverImg: 'taping-lg.jpg',
		coverImgSmall: 'taping-sm.jpg',
		link: '/kinesiotaping',
	},
	{
		id: 9,
		title: 'ACL(więzadło krzyżowe przednie)',
		coverImgSmall: 'acl.jpg',
		link: '/acl',
		text: 'Wyłącznie prywatnie',
	},
	{
		id: 10,
		title: 'USG',
		coverImg: 'usg-lg.jpg',
		coverImgSmall: 'usg-sm.jpg',
		link: '/usg',
		text: 'Wyłącznie prywatnie',
	}
];
