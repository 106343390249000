import React, { useEffect } from 'react';
import SectionTitle from './utilis/SectionTitle';
import './MainFala.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function MainFala() {
	useEffect(() => {
		AOS.init({
			duration: 2500,
		});
		// AOS.refresh();
	}, []);
	return (
		<>
			<div className='container'>
				<SectionTitle heading='Fala uderzeniowa' subheading='' />
				<div className='fala'>
					<div className='fala__img'></div>
					<div className='fala__boxes'>
						<ul className='fala__lista'>
							<h3 className='fala__h3'><i className="fas fa-check"></i> Wskazania:</h3>
							<li className='fala__item'>ostroga piętowa,</li>
							<li className='fala__item'>
								zapalenie powięzi podeszwowej stopy,
							</li>
							<li className='fala__item'>ból ścięgna Achillesa,</li>
							<li className='fala__item'>łokieć tenisisty,</li>
							<li className='fala__item'>łokieć golfisty,</li>
							<li className='fala__item'>
								zespół mięśnia piszczelowego przedniego,
							</li>
							<li className='fala__item'>
								ból ścięgien rzepki (kolano skoczka),
							</li>
							<li className='fala__item'>
								bóle stawów barkowych w wyniku zwapnień,
							</li>
							<li className='fala__item'>entezopatia stawu biodrowego,</li>
							<li className='fala__item'>zespół cieśni nadgarstka</li>
						</ul>
						<ul className='fala__lista'>
							<h3 className='fala__h3'><i className="fas fa-times"></i> Przeciwskazania:</h3>
							<li className='fala__item'>
								zaburzenia krzepnięcia krwi (hemofilia),
							</li>
							<li className='fala__item'>
								stosowanie leków przeciwzakrzepowych,
							</li>
							<li className='fala__item'>choroba nowotworowa,</li>
							<li className='fala__item'>ciąża,</li>
							<li className='fala__item'>
								polineuropatia w cukrzycy (pompa insulinowa),
							</li>
							<li className='fala__item'>dzieci w okresie wzrostu,</li>
							<li className='fala__item'>osteoporoza</li>
						</ul>
						<ul className='fala__lista'>
							<h3 className='fala__h3'><i className="fas fa-spinner"></i> Efekty biologiczne:</h3>
							<li className='fala__item'>
                            efekt komórkowy- wzrost przekaźnictwa przez błony komórkowe,
							</li>
							<li className='fala__item'>
                            poprawa krążenia krwi w obszarze ścięgien i mięśni,
							</li>
							<li className='fala__item'>poprawa metabolizmu tkanek,</li>
							<li className='fala__item'>rozpuszczanie zwapnionych fibroblastów,</li>
							<li className='fala__item'>
                            zwiększona produkcja kolagenu,
							</li>
							<li className='fala__item'>redukcja napięcia tkanek,</li>
							<li className='fala__item'>wpływ przeciwbólowy</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
}
