import React, { useEffect, useState } from 'react';
import '../App.css';
import './MainFizykoterapia.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {Zabiegi} from '../zabiegi';


import SectionTitle from './utilis/SectionTitle';

function MainFizykoterapia() {
	useEffect(() => {
		AOS.init({
			duration: 3000,
		});
	}, []);
      const [query, setQuery] = useState("");

	return (
		<>
			<div className='container'>
				<SectionTitle heading='Fizykoterapia' subheading='' />
				<div className='app'>
					<input
						className='search'
						placeholder='Szukaj...'
						onChange={(e) => setQuery(e.target.value.toLowerCase())}
					/>
					<ul className='list'>
						{Zabiegi.filter((asd) =>
							asd.title.toLowerCase().includes(query)
						).map((zabieg) => (
							<div className='boxItem' key={zabieg.id} data-aos={'flip-up'}>
                                <h3 className='fizyko__h3'>{zabieg.title}</h3>
								<p className='fizyko__p'>{zabieg.desc}</p>
							</div>
						))}
					</ul>
				</div>
			</div>
		</>
	);
}

export default MainFizykoterapia;
