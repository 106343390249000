import React, { useEffect } from 'react';
import SectionTitle from './utilis/SectionTitle';
import './Wizyty.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Wizyty() {
	useEffect(() => {
		AOS.init({
			duration: 2500,
		});
		// AOS.refresh();
	}, []);
	return (
		<>
			<div className='container'>
				<SectionTitle
					heading='Rehabilitacja w warunkach domowych'
					subheading='NFZ'
				/>
				<div className='wizyty'>
					<h3 className='wizyty__h3' data-aos={"fade-right"}>
						Rehabilitacja środowiskowa w domu pacjenta przeznaczona jest dla
						osób, które nie poruszają się samodzielnie i nie mają możliwości
						dotarcia do placówki rehabilitacyjnej.
					</h3>
					<div className='wizyty__img'></div>
					<p className='wizyty__text'>
						Narodowy Fundusz Zdrowia finansuje do 80 dni zabiegowych w roku
						kalendarzowym. W rehabilitacji domowej wykonywanych jest do 5
						zabiegów dziennie. Skierowanie na zabiegi fizjoterapeutyczne
						wystawia lekarz podstawowej opieki zdrowotnej(POZ), lekarz
						specjalista w dziedzinie rehabilitacji w chorobach narządu ruchu,
						rehabilitacji ogólnej, medycyny fizykalnej i balneoklimatologii,
						reumatologii, neurologii, neurochirurgii, chirurgii, ortopedii i
						traumatologii narządów ruchu. Skierowanie na zabiegi
						fizjoterapeutyczne w warunkach domowych jest ważne 30 dni od daty
						wystawienia. W tym czasie skierowanie musi zostać zarejestrowane,
						bez względu na to, kiedy rozpocznie się rehabilitacja. Zabiegi
						fizjoterapeutyczne w warunkach domowych są udzielane pacjentom z
						takimi zaburzeniami jak:
					</p>
					<ul className="wizyty__lista">
						<li className="wizyty__item" data-aos={"zoom-in-up"}>Ogniskowe uszkodzenia mózgu ( stany po zatorach mózgowych, udarach krwotocznych mózgu, urazach) – przez okres 12 miesięcy od dnia powstania choroby,</li>
						<li className="wizyty__item" data-aos={"zoom-in-up"}>Ciężkie uszkodzenia centralnego i obwodowego układu nerwowego,</li>
						<li className="wizyty__item" data-aos={"zoom-in-up"}>Uszkodzenia rdzenia kręgowego – przez okres 12 miesięcy od dnia powstania,</li>
						<li className="wizyty__item" data-aos={"zoom-in-up"}>Choroby przewlekłe postępujące: miopatie, choroba Parkinsona, zapalenia wielomięśniowe, rdzeniowy zanik mięśni, guzy mózgu, procesy demielinizacyjne, przewlekłe zespoły pozapiramidowe, reumatoidalne zapalenie stawów,</li>
						<li className="wizyty__item" data-aos={"zoom-in-up"}>Choroby zwyrodnieniowe stawów biodrowych lub kolanowych, po zabiegach endoprotezoplastyki stawu- przez okres 6 miesięcy od dnia powstania urazu,</li>
						<li className="wizyty__item" data-aos={"zoom-in-up"}>Osobom w stanie wegetatywnym lub apalicznym,</li>
						<li className="wizyty__item" data-aos={"zoom-in-up"}>Osobom posiadającym oświadczenie o znacznym stopniu niepełnosprawności.</li>
					</ul>
				</div>
			</div>
		</>
	);
}
