import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Info from './Info';
import logo from '../assets/Image/Logo.svg';


const Navbar = (props) => {
	const [click, setClick] = useState(false);
	const [button, setButton] = useState(true);

	const handleClick = () => setClick(!click);
	const closeMobileMenu = () => setClick(false);
	const showButton = () => {
		if (window.innerWidth <= 960) {
			setButton(false);
		} else {
			setButton(true);
		}
	};

	useEffect(() => {
		showButton();
	}, []);

	return (
		<>
			<Info />
			<nav className={`navbar ${props.darkMode ? '' : 'dark'}`}>
				<div className='navbar-container'>
					<Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
						<img className='navbar-img' src={logo} alt='logo NZOZ Fizjo-Medica'></img>
					</Link>
					<div className='menu-icon' onClick={handleClick}>
						<button className='burger-btn'>
							<div className='burger-btn__box'>
								<div className='burger-btn__bars'> </div>
							</div>
						</button>
					</div>

					<ul className={click ? 'nav-menu active' : 'nav-menu'}>
						<li className='nav-item'>
							<Link to='/' className='nav-links' onClick={closeMobileMenu}>
								Strona Główna
							</Link>
						</li>
						<li className='nav-item'>
							<Link
								to='/wizyta'
								className='nav-links'
								onClick={closeMobileMenu}>
								Pierwsza wizyta
							</Link>
						</li>
						<li className='nav-item'>
							<Link
								to='/uslugi'
								className='nav-links'
								onClick={closeMobileMenu}>
								Zakres Usług
							</Link>
						</li>
						<li className='nav-item'>
							<Link to='/usg' className='nav-links' onClick={closeMobileMenu}>
								USG
							</Link>
						</li>
						<li className='nav-item'>
							<Link
								to='/cennik'
								className='nav-links'
								onClick={closeMobileMenu}>
								Cennik
							</Link>
						</li>
						<li className='nav-item'>
							<Link
								to='/kontakt'
								className='nav-links'
								onClick={closeMobileMenu}>
								Kontakt
							</Link>
						</li>
					</ul>
					<div className='toggler'>
						<p className='toggler--light'>
							<i className='fas fa-sun'></i>
						</p>
						<div className='toggler--slider' onClick={props.toggleDarkMode}>
							<div className='toggler--slider--circle'></div>
						</div>
						<p className='toggler--dark'>
							<i className='fas fa-moon'></i>
						</p>
					</div>
				</div>
			</nav>
		</>
	);
};

export default Navbar;
