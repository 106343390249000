import React from 'react';
// import { Helmet, HelmetProvider } from 'react-helmet';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Contact from './components/pages/Contact';
import Wizyta from './components/pages/Wizyta';
import Uslugi from './components/pages/Uslugi';
import Cennik from './components/pages/Cennik';
import Domowa from './components/pages/Domowa';
import Fizykoterapia from './components/pages/Fizykoterapia';
import Kinezyterapia from './components/pages/Kinezyterapia';
import Masaz from './components/pages/Masaz';
import Terapia from './components/pages/Terapia';
import Iglowanie from './components/pages/Iglowanie';
import Tkanki from './components/pages/Tkanki';
import Fala from './components/pages/Fala';
import Kinesiotaping from './components/pages/Kinesiotaping';
import Acl from './components/pages/Acl';
import Usg from './components/pages/Usg';
import Gift from './components/pages/Gift';
import ThankYou from './components/pages/ThankYou';
import Error from './components/pages/Error';
import Navbar from './components/Navbar';
import ScrollToTop from './components/utilis/ScrollToTop';

function App() {
	const [darkMode, setDarkMode] = React.useState(true);

	function toggleDarkMode() {
		setDarkMode((newVal) => !newVal);
	}
	return (
		<HelmetProvider>
			<section className={darkMode ? '' : 'dark'}>
				<Helmet>
					{/* <!-- HTML Meta Tags --> */}
					<title>NZOZ Fizjo-Medica</title>
					<link rel='canonical' href='https://www.fizjo-medica.eu/' />
					<meta
						name='description'
						content='NZOZ Fizjo-Medica to zespół doświadczonych fizjoterapeutów. Stosujemy różnorodne techniki i metody, takie jak m.in. USG, suche igłowanie, fala uderzeniowa, masaż, fizykoterapia, czy kinezyterapia.'
						data-react-helmet='true'
					/>
					<meta
						name='keywords'
						content='fizjoterapia, tyczyn, NFZ, zabiegi, USG, rehabilitacja, suche igłowanie, ćwiczenia, masaż, zdrowie'
						data-react-helmet='true'
					/>

					{/* <!-- Google / Search Engine Tags --> */}
					<meta
						itemprop='description'
						content='NZOZ Fizjo-Medica to zespół doświadczonych fizjoterapeutów. Stosujemy różnorodne techniki i metody, takie jak m.in. USG, suche igłowanie, fala uderzeniowa, masaż, fizykoterapia, czy kinezyterapia.'
						data-react-helmet='true'
					/>
					<meta
						itemprop='keywords'
						content='fizjoterapia, tyczyn, NFZ, zabiegi, USG, rehabilitacja, suche igłowanie, ćwiczenia, masaż, zdrowie'
						data-react-helmet='true'
					/>
				</Helmet>
				<ScrollToTop />
				<Navbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
				<Routes>
					<Route path='/' element={<Home darkMode={darkMode} />} />
					<Route path='/wizyta' element={<Wizyta darkMode={darkMode} />} />
					<Route path='/uslugi' element={<Uslugi darkMode={darkMode} />} />
					<Route path='/w-domu' element={<Domowa darkMode={darkMode} />} />
					<Route
						path='/fizykoterapia'
						element={<Fizykoterapia darkMode={darkMode} />}
					/>
					<Route
						path='/kinezyterapia'
						element={<Kinezyterapia darkMode={darkMode} />}
					/>
					<Route path='/masaz' element={<Masaz darkMode={darkMode} />} />
					<Route path='/terapia' element={<Terapia darkMode={darkMode} />} />
					<Route
						path='/iglowanie'
						element={<Iglowanie darkMode={darkMode} />}
					/>
					<Route path='/tkanki' element={<Tkanki darkMode={darkMode} />} />
					<Route path='/fala' element={<Fala darkMode={darkMode} />} />
					<Route
						path='/kinesiotaping'
						element={<Kinesiotaping darkMode={darkMode} />}
					/>
					<Route path='/acl' element={<Acl darkMode={darkMode} />} />
					<Route path='/usg' element={<Usg darkMode={darkMode} />} />
					<Route path='/bon' element={<Gift darkMode={darkMode} />} />
					<Route path='/cennik' element={<Cennik darkMode={darkMode} />} />
					<Route path='/kontakt' element={<Contact darkMode={darkMode} />} />
					<Route path='/thankyou' element={<ThankYou darkMode={darkMode} />} />
					<Route path='*' element={<Error darkMode={darkMode} />} />
				</Routes>
			</section>
		</HelmetProvider>
	);
}

export default App;
