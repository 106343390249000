import React from 'react';
import '../../App.css';
import MainMasaz from '../MainMasaz';
import Footer from '../Footer';
import TabTitle from '../utilis/TabTitle';

function Masaz() {
	TabTitle('Masaż');
	return (
		<>
			<MainMasaz />
			<Footer />
		</>
	);
}

export default Masaz;
