import React, { useEffect } from 'react';
import SectionTitle from './utilis/SectionTitle';
import './MainIglowanie.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function MainMasaz() {
	useEffect(() => {
		AOS.init({
			duration: 2500,
		});
		// AOS.refresh();
	}, []);
	return (
		<>
			<div className='container'>
				<SectionTitle heading='Suche Igłowanie' subheading='' />
				<div className='iglowanie'>
					<p className='iglowanie__h3' data-aos={'fade-right'}>
                    Znajduje zastosowanie u pacjentów z ostrym oraz przewlekłym bólem, którego przyczyną są przeciążenia mięśniowe, stres, niedobór witamin i mikroelementów, zwyrodnienia stawów.<br/> Igłoterapia polega na wbiciu bardzo cienkiej sterylnej igły o jednorazowym zastosowaniu w odpowiedni punkt spustowy znajdujący się w przebiegu włókien mięśniowych powierzchownych i głębokich. Mięśniowo-powięziowe punkty spustowe to bolesne miejsca w przebiegu pasma mięśniowego, które pod naciskiem są tkliwe, a ból promieniuje do sąsiednich okolic ciała.<br/> Wskazania: łokieć golfisty, tenisisty, napięciowy ból głowy, ból stawu barkowego, kolano biegacza, zapalenie rozcięgna podeszwowego, ostroga piętowa, syndrom pasma biodrowo-piszczelowego.
					</p>
					<div className='iglowanie__img'></div>
				</div>
			</div>
		</>
	);
}
