import React from 'react';
import '../../App.css';
import GiftHeader from '../GiftHeader';
import Footer from '../Footer';
import TabTitle from '../utilis/TabTitle';

function Gift() {
	TabTitle('Bon Podarunkowy');
	return (
		<>
			<GiftHeader />
			<Footer />
		</>
	);
}

export default Gift;
